import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, FigureDouble, List,
} from '../../components/opComponents';

const FractureDeClavicule = () => (
  <Layout
    title="Fracture de clavicule : Diagnostic et traitement"
    description="Comment traiter une fracture de la Clavicule ? Tout savoir avec les conseils du Dr Ghazanfari spécialiste de la chirurgie des membres supérieurs."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-lepaule" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de l&apos;épaule</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Fracture de clavicule" />
        <MainPart title="Quel public est le plus enclin à présenter une fracture de la clavicule ?">
          <Typography>
            La fracture de la clavicule représente 5% des fractures de l&apos;adulte
            . Dans 80% des cas, elle se situe au niveau de son 1/3 moyen.
            Elle survient plus fréquemment chez le sujet jeune (moins de 40 ans)
            avec une nette prédominance masculine.
          </Typography>
        </MainPart>
        <MainPart title="Comment s'articule la clavicule ?">
          <Typography>
            La clavicule s&apos;articule avec le sternum (manubrium)
            médialement et avec la scapula (acromion
            latéralement. Elle est située au-dessus du
            {' '}
            <a className="underline text-link hover:text-primary" href="https://fr.wikipedia.org/wiki/Plexus_brachial" target="_blank" rel="noreferrer">plexus brachial</a>
            {' '}
            et des vaisseaux (artère et veine) sous-claviers (Figure 1).
          </Typography>
          <Figure imageName="figure1-fracturedeclavicule.jpg" caption="Figure 1. Anatomie osseuse de l&apos;épaule" currentOpTitle="Fracture de clavicule" />
        </MainPart>
        <MainPart title="Quelles sont les causes possibles d'une fracture de la clavicule ?">
          <Typography>
            La fracture de la clavicule est fréquemment observée dans le cadre d&apos;un
            traumatisme à haute énergie, après accident sportif (rugby, ski, vélo...)
            ou sur la voie publique (voiture, moto...).
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les symptômes d'une fracture de la clavicule ?">
          <Typography>
            Le diagnostic est évoqué en cas de présence des signes suivants :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                Douleurs en regard de la clavicule
                avec impossibilité bouger le bras
              </Typography>
            </List.Item>
            <List.Item><Typography>Hématome en regard de la clavicule</Typography></List.Item>
            <List.Item>
              <Typography>
                Une tuméfaction voire une déformation
                de la clavicule
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Chute du moignon de l&apos;épaule (ptose)
                sous l&apos;effet du poids du membre
              </Typography>
            </List.Item>
          </List>
          <Typography>
            Lors de l&apos;examen clinique, il est très important
            de rechercher d&apos;éventuelles complications
            de la fracture : une ouverture/menace cutanée par un fragment osseux, une atteinte
            vasculaire (prendre le pouls), une atteinte du plexus brachial et enfin des
            difficultés respiratoires faisant évoquer un pneumothorax (notamment en cas
            de fracture de côte associée).
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les examens qui permettent de confirmer une fracture de la clavicule ?">
          <List>
            <List.Item>
              <Typography>
                Radiographies bilatérales et comparatives des clavicules : permettent de localiser
                le siège de la fracture, d&apos;identifier le nombre de
                fragments et de quantifier le
                déplacement des fragments ainsi que le raccourcissement de la clavicule (Figure 2).
              </Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure2-fracturedeclavicule.jpg"
            caption="Figure 2. Radiographies de l&apos;épaule: fracture déplacée de la clavicule"
            currentOpTitle="Fracture de clavicule"
          />
          <List>
            <List.Item>
              <Typography>
                Un scanner avec des reconstructions osseuse en 3
                dimensions peut être nécessaire en cas de fracture complexe.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                En cas de suspicion de lésion vasculaire, un scanner avec
                injection de produit de contraste (Angio-scanner) est prescrit.

              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                En cas de lésion neurologique associée, une IRM du plexus brachial
                et un électromyogramme (le plus souvent fait à J21) sont indispensables.
              </Typography>
            </List.Item>
          </List>
          <div>
            <Typography>
              Le traitement orthopédique (non chirurgical) est indiqué en cas de fracture peu
              déplacée avec présence d&apos;un contact inter-fragmentaire
              et d&apos;un raccourcissement
              de moins de 2 centimètres de la clavicule, sans complications associée
              (cutanée et vasculo-nerveuse).
            </Typography>
            <Typography>
              L&apos;épaule est immobilisée par des anneaux ou par une simple
              écharpe afin de favoriser
              la consolidation de la clavicule (Figure 3). Tout geste de réduction de la fracture
              lors de la mise en place de l&apos;immobilisation est
              à proscrire en raison de son inefficacité.
              La durée de l&apos;immobilisation varie de 4 à 6 semaines selon le type de
              fracture et la qualité de l&apos;os.
            </Typography>
          </div>
          <FigureDouble
            maxWidth="max-w-[600px]"
            imageNameOne="figure3-fracturedeclavicule.jpg"
            imageNameTwo="figure3bis-fracturedeclavicule.jpg"
            caption="Figure 3. Immoblisation post-opératoire: gilet coude au corps (image de gauche), anneaux claviculaires (image de droite)"
            currentOpTitle="Fracture de clavicule"
          />
        </MainPart>
        <MainPart title="Quels sont les traitements médicaux et chirurgicaux qui permettent de traiter une fracture de la clavicule ?">
          <Typography>
            La prise d&apos;antalgiques, d&apos;anti-inflammatoires
            non-stéroïdiens (7 jours maximum)
            et le glaçage de la clavicule (10 minutes, 3 fois par jour) permettent
            de diminuer la douleur occasionnée par la fracture.
          </Typography>
          <Typography>Le traitement chirurgical est indiqué dans les cas suivants :</Typography>
          <List>
            <List.Item>
              <Typography>
                Fracture déplacée avec absence
                de contact inter-fragmentaire
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Raccourcissement de plus de 2
                centimètres de la clavicule
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Complication cutanée : menace
                cutanée, fracture ouverte (Figure 4)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Complication neurologique
                (atteinte du plexus brachial)
              </Typography>

            </List.Item>
            <List.Item><Typography>Complication vasculaire</Typography></List.Item>
            <List.Item>
              <Typography>
                Accident grave : polytraumatisé, fracture de clavicule bilatérale,
                épaule flottante (fracture de la glène et de la clavicule)
                Sportif de haut niveau afin de garantir un bon résultat fonctionnel
                en vue d&apos;une reprise plus rapide de la compétition
              </Typography>
            </List.Item>
          </List>
          <Figure maxWidth="max-w-[400px]" imageName="figure4-fracturedeclavicule.jpg" caption="Figure 4. Fracture de la clavicule. Saillie d&apos;un fragment osseux sous la peau  avec menace cutanée." currentOpTitle="Fracture de clavicule" />
          <Typography>
            L&apos;opération est réalisée sous anesthésie générale. Une anesthésie locorégionale
            (bloc inter-scalénique) peut y être associé afin de limiter
            les douleurs post-opératoires.
          </Typography>
          <Typography>
            L&apos;incision est réalisée au niveau du bord antérieur de la
            clavicule. Après réduction de
            la fracture, une plaque anatomique verrouillée est mise en place sur la face supérieure
            de la clavicule (Figure 5). Afin d&apos;éviter la survenue d&apos;hématome,
            un drain est mis en place au niveau du site opératoire.
          </Typography>
          <Figure maxWidth="max-w-[400px]" imageName="figure5-fracturedeclavicule.jpg" caption="Figure 5. Réduction et ostéosynthèse de la clavicule par plaque" currentOpTitle="Fracture de clavicule" />
          <Typography>
            Le patient est en général hospitalisé une nuit après l&apos;opération
            . Le jour de sa sortie,
            le pansement est refait et le drain retiré. L&apos;immobilisation est
            réalisée à l&apos;aide
            d&apos;un gilet orthopédique, pour une durée de 4 à 6 semaines selon la fracture.
            La rééducation est débutée dès l&apos;ablation du gilet et la reprise du sport
            est autorisée à partir du 4ème mois post-opératoire.
          </Typography>
          <Typography weight="bold">
            Les fractures latérales ou du quart externe de la clavicule constituent un cas à part
            {' '}
            <Typography variant="span">: elles sont l&apos;équivalent d&apos;une disjonction acromio-claviculaire.</Typography>
          </Typography>
          <Typography>
            En cas de fracture déplacée, une prise en charge chirurgicale est vivement conseillée
            en raison du risque important de non consolidation (pseudarthrose) de la fracture,
            se traduisant par une gêne fonctionnelle majeure de l&apos;épaule par la suite.
          </Typography>
          <Typography>
            Compte-tenu de la petite taille du fragment, la mise en place
            d&apos;une plaque de clavicule
            n&apos;est souvent pas possible. La fracture est donc
            stabilisée par la mise en place de deux
            boutons (1 sur la clavicule et 1 sous la coracoïde) reliés entre eux par deux fils
            hautement résistants. Cette intervention est réalisable sous arthroscopie. Les suites
            post-opératoires sont identiques à celles d&apos;une plaque de clavicule (Figure 6).
          </Typography>
          <FigureDouble
            maxWidth="max-w-[500px]"
            imageNameOne="figure6-fracturedeclavicule.jpg"
            imageNameTwo="figure6bis-fracturedeclavicule.jpg"
            caption="Figure 6. Fracture déplacée du quart externe de la clavicule. Aspect post-opératoire après réduction et mise en place de DogBones sous arthroscopie."
            currentOpTitle="Fracture de clavicule"
          />
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération d'une fracture de la clavicule ?">
          <Typography size="mdx" weight="bold">Traitement non chirurgical :</Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Cal vicieux ou consolidation en mauvaise position de la clavicule.
                {' '}
                <Typography variant="span">
                  Cette complication peut être responsable d&apos;une gêne fonctionnelle
                  (douleur à la mobilisation
                  de l&apos;épaule et/ou à l&apos;appui du cal, fatigabilité de l&apos;épaule...),
                  d&apos;une irritation du plexus
                  brachial (syndrome du défilé thoraco-brachial) et d&apos;une gêne esthétique.
                  En cas de gêne persistance, une intervention chirurgicale
                  est souvent nécessaire afin
                  de restaurer l&apos;anatomie : ostéotomie de la clavicule
                  (recasser l&apos;os au niveau du cal)
                  associée à la mise en place d&apos;une plaque anatomique
                  +/- greffe osseuse prélevée sur
                  le bassin (crête iliaque).
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Pseudarthrose ou non consolidation de la clavicule
                {' '}
                <Typography variant="span">
                  (15% des cas). Les facteurs de risque de survenue de pseudarthrose sont
                  : l&apos;âge avancé du patient, le sexe féminin, le tabac et le type de
                  fracture (fractures à plusieurs fragments, fractures déplacées sans
                  contact inter-fragmentaire, un raccourcissement de plus de 2 centimètres
                  de la clavicule).
                  En plus de la gêne esthétique, la pseudarthrose est responsable d&apos;importantes
                  douleurs de la clavicule limitant l&apos;usage de l&apos;épaule.
                  Une prise en charge chirurgicale est le plus souvent indiquée dans ce cas
                  : avivement de l&apos;os, mise en place d&apos;une greffe osseuse prélevée sur le
                  bassin et d&apos;une plaque anatomique.
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Traitement chirurgical de la fracture de l&apos;épaule :</Typography>
          <List>
            <List.Item><Typography weight="bold">Complications liées à l&apos;anesthésie.</Typography></List.Item>
            <List.Item>
              <Typography weight="bold">
                Lésions iatrogènes plexus brachial
                <Typography variant="span">, des vaisseaux sous-claviers et du poumon.</Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Pseudarthrose
                <Typography variant="span">
                  {' '}
                  : très rare chez les patients opérés (moinst de 5% des cas). Cette complication
                  nécessite une prise en charge chirurgicale avec ablation du matériel, avivement
                  du foyer de pseudarthrose, greffe osseuse iliaque et mise en place
                  d&apos;une nouvelle
                  plaque verrouillée. Des prélèvements bactériologiques sont indispensables lors
                  de l&apos;opération afin d&apos;éliminer une infection sous-jacente.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Démontage de la plaque
                <Typography variant="span">
                  {' '}
                  en cas d&apos;utilisation de matériel inadéquat.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Infection
                <Typography variant="span">
                  {' '}
                  :
                  rare en raison de l&apos;application des règles d&apos;hygiène
                  et de l&apos;administration
                  d&apos;une antibioprophylaxie pendant l&apos;opération. Le germe le
                  plus souvent incriminé est le P.Acnès.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Hématome
                <Typography variant="span">
                  {' '}
                  :
                  limité par la mise en place d&apos;un drain post-opératoire.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Nécrose cutanée
                <Typography variant="span">
                  {' '}
                  en cas de traction excessive sur la peau. Complication limitée
                  par la réalisation d&apos;un incision cutané large le long de la clavicule.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Perte de la sensibilité
                <Typography variant="span">
                  {' '}
                  au niveau de la cicatrice : en rapport avec la section des petites
                  branches du nerf supra-claviculaire lors de l&apos;intervention.
                  La récupération sensitive est la règle.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Raideur de l&apos;épaule
                <Typography variant="span">
                  {' '}
                  :
                  liée à l&apos;immobilisation post-opératoire. L&apos;évolution est le plus
                  souvent favorable avec les séances de rééducation.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Capsulite rétractile
                <Typography variant="span">
                  {' '}
                  (ou algodystrophie) : se traduisant par des douleurs et un enraidissement
                  de l&apos;épaule pouvant atteindre tout le membre supérieur. Il s&apos;agit
                  d&apos;une complication non prévisible, pouvant durer 12 à 18 mois.
                </Typography>
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="epaule" />
  </Layout>
);

export default FractureDeClavicule;
